const zendesk = {
  "overview": {
    "mrr_won": 274529.26,
    "pocs_developed": "12",
    "whitepaper": "3",
    "bug_reports": "8",
    "bootcamp": "8",
    "initiatives": "2"
  },
  "bootstrapper": {
    "total_jobs_performed": "274",
    "total_api_calls": "4654",
    "time_saved_in_hours": "126"
  }
}

export default zendesk;