import React, { useEffect } from 'react';
import { Route, useLocation, Switch } from 'react-router-dom'
import configs from './config/config'
import ReactGA from 'react-ga';

import Password from './components/Password'
import Zendesk from './components/Zendesk'

function usePageViews() {
  const location = useLocation();
  ReactGA.initialize(configs.google.analyticsId, { debug: false });
  useEffect(() => {
    if (configs.google.analyticsId) {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [location]);
}

const App = () => {
  usePageViews()
  return (
    <Switch>
      <Route exact path="/" component={Password} />
      <Route path="/zendesk" component={Zendesk} />
    </Switch>
  )
}
export default App;