import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";

const Password = (props) => {
  const [isLoginSuccess, setIsLoginSuccess] = useState(false);
  const [data, setData] = useState();
  const [error, setError] = useState();
  const apiPath = 'http://www.mocky.io/v2/5e883a0431000083003f4888'; // http://www.mocky.io/v2/5e8854763100004c003f48dd http://www.mocky.io/v2/5e883a0431000083003f4888

  const handleSubmit = async () => {
    const enteredPassword = document.querySelector('#password').value;
    const payload = {
      'password': enteredPassword
    };
    try {
      const result = await fetch(apiPath, {
        method: 'POST', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      const jsonResult = await result.json();
      setError(false);
      setData(jsonResult);
    } catch (err) {
      setError('Invalid Login');
    }
    
  }

  const renderSuccess = () => {
    return (
      <div className="mb-5">
        <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-600">Password</label>
        <input type="password" name="password" id="password" className="block w-full p-3 rounded bg-gray-200 border border-transparent focus:outline-none" />
      </div>      
    );
  };

  const renderError = () => {
    return (
      <div className="mb-5">
        <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-600">Password</label>
        <input type="password" name="password" id="password" className="block w-full p-3 rounded bg-gray-200 border border-transparent focus:outline-none border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red sm:text-sm sm:leading-5" />  
        <span className="mt-2 text-sm text-red-600">Invalid Password</span>
      </div>
    );
  }

  return (
    <div className="bg-gray-200 font-sans text-gray-700">
      <div className="container mx-auto p-8 flex h-screen">
          <div className="max-w-md w-full m-auto h-auto">
              <h1 className="text-4xl text-center mb-4 font-thin">Leroy Chan | <span className="text-orange-400">Portfolio</span></h1>
              <div className="bg-white rounded-lg overflow-hidden shadow-2xl">
                  <div className="p-8">
                    { error ? renderError() : renderSuccess()  }
                    <button className="w-full p-3 mt-4 bg-indigo-600 text-white rounded shadow" onClick={handleSubmit}>Access</button>
                  </div>
              </div>
          </div>
      </div>
  </div>
  );
}

export default Password;