import React, { useState, useEffect } from 'react';
import zendeskData from '../data/zendesk';
import grab from '../images/grab.png'
import coupang from '../images/coupang.png'
import amaysim from '../images/amaysim.png'
import klook from '../images/klook.png'
import circles from '../images/circles.png'
import swire from '../images/swire.png'
import bootstrapper from '../images/bootstrapper.png'
import bootstrapperJob from '../images/bootstrapper_job.png'
import pocTriagingBot from '../images/poc_triagingbot.png'
import pocTranslation from '../images/poc_translation.png'
import pocMultilingual from '../images/poc_multilingual.png'
import pocOrderInventory from '../images/poc_orderinventory.png'
import pocChatPayment from '../images/poc_chatpayment.png'
import pocCSATWhatsApp from '../images/poc_csatwhatsapp.png'
import pocMultipleTickets from '../images/poc_multipletickets.png'
import pocAmazonConnect from '../images/poc_amazonconnect.png'
import pocClickToChat from '../images/poc_clicktochat.png'
import pocCrossChannelBot from '../images/poc_crosschannelbot.png'
import pocRandomizedGreetings from '../images/poc_randomizedgreetings.png'
import pocChatRedaction from '../images/poc_chatredaction.png'

const Zendesk = (props) => {
  const [tenure, setTenure] = useState();
  const [arrAmount, setArrAmount] = useState();
  const [data, setData] = useState(zendeskData);

  useEffect(() => {
    const current = new Date();
    const startDate = new Date("2019-02-18");
    const time = (current.getTime() - startDate.getTime()) / 1000;
    const year  = Math.abs((time/(60 * 60 * 24))/365.25);
    setTenure(`${year.toFixed(2)}`);
    const arrAmount = formatMoney(data.overview.mrr_won * 12)
    setArrAmount(arrAmount);
  },[]);

  const formatMoney = (amount, decimalCount = 2, decimal = ".", thousands = ",") => {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? "-" : "";

      let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      let j = (i.length > 3) ? i.length % 3 : 0;

      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
      console.log(e)
    }
  };

  return(
    <div className="font-sans antialiased text-gray-600 leading-normal">
        <div className="container mx-auto mx-4">
          <header className="my-4 border-b border-gray-200 ">
            <div className="pb-2 flex">
              <span className="text-2xl">LEROY</span>
              <span className="text-2xl font-thin">CHAN</span>
              <span className="text-l mx-2 mt-1">for</span>
              <div className="">
                <svg xmlns="http://www.w3.org/2000/svg" fill="#03363d" className="w-40" viewBox="0 0 100 20">
                  <path d="M67.63,16.74a5,5,0,0,0,3.59-1.4L73,17.27a7,7,0,0,1-5.35,2.12c-4.35,0-7.16-2.9-7.16-6.82a6.67,6.67,0,0,1,6.77-6.82c4.32,0,6.77,3.3,6.55,7.88H63.62A3.68,3.68,0,0,0,67.63,16.74Zm3.11-5.29A3.27,3.27,0,0,0,67.3,8.36a3.51,3.51,0,0,0-3.69,3.08Z"/>
                  <path d="M0,16.6l7.86-8H.18V6.07H11.5V8.63l-7.86,8h8v2.52H0Z"/>
                  <path d="M21,16.74a5,5,0,0,0,3.59-1.4l1.78,1.93a7,7,0,0,1-5.35,2.12c-4.35,0-7.16-2.9-7.16-6.82a6.67,6.67,0,0,1,6.77-6.82c4.32,0,6.77,3.3,6.55,7.88H17A3.68,3.68,0,0,0,21,16.74Zm3.11-5.29a3.27,3.27,0,0,0-3.44-3.08A3.51,3.51,0,0,0,17,11.44Z"/>
                  <path d="M44.18,12.57a6.53,6.53,0,0,1,6.55-6.81,5.64,5.64,0,0,1,4.35,2V0h2.78V19.12H55.08V17.32a5.57,5.57,0,0,1-4.37,2.07A6.59,6.59,0,0,1,44.18,12.57Zm11.05,0a4.14,4.14,0,1,0-8.28,0,4.14,4.14,0,1,0,8.28,0Z"/>
                  <path d="M75.55,16.35,78.06,15a3.73,3.73,0,0,0,3.35,1.91c1.57,0,2.38-.81,2.38-1.73s-1.52-1.28-3.17-1.62c-2.23-.47-4.53-1.2-4.53-3.9,0-2.07,2-4,5.06-4a6,6,0,0,1,5.27,2.54L84.09,9.56a3.38,3.38,0,0,0-2.93-1.49c-1.49,0-2.25.73-2.25,1.57s1.2,1.2,3.09,1.62c2.15.47,4.58,1.18,4.58,3.9,0,1.81-1.57,4.24-5.29,4.22A6.12,6.12,0,0,1,75.55,16.35Z"/>
                  <path d="M93.81,13.31l-2.2,2.41v3.41H88.83V0h2.78V12.52L97.5,6h3.38l-5.11,5.61L101,19.12H97.87Z"/>
                  <path d="M35.92,5.76c-3.3,0-6.06,2.15-6.06,5.71v7.65h2.83v-7.3A3.1,3.1,0,0,1,36,8.39c2.1,0,3.14,1.29,3.14,3.43v7.3H42V11.47C42,7.91,39.22,5.76,35.92,5.76Z"/>
                </svg>
              </div>
            </div>
          </header>
          <div className="">
            <div className="flex justify-center">
                <img src="https://en.gravatar.com/userimage/925970/6550edd7553d518667f66a1da071ecb3.jpg?size=200" className="rounded-full border-solid border-white border-4" alt="Leroy Chan" />   
            </div>
            <div className="rounded rounded-t-lg overflow-hidden shadow my-8 -mt-12">
              <div className="text-center px-3 pb-6 pt-16">
                <h3 className="text-2xl bold">Leroy Chan</h3>
                <p className="mt-2 font-light text-grey-dark">Senior Platform Architect | APAC</p>
              </div>
              <div className="flex justify-center pb-3 text-grey-dark">
                <div className="text-center">
                  <h2>{tenure}</h2>
                  <span>Years in Zendesk</span>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="text-center">
                <h2 className="text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
                  Overview
                </h2>
              </div>
            <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
              <div className="bg-white overflow-hidden shadow rounded-lg">
                <div className="px-4 py-5 sm:p-6">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 bg-indigo-500 rounded-md p-3">
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="dollar-sign" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 288 512" className="h-6 w-6 text-white">
                        <path fill="currentColor" d="M209.2 233.4l-108-31.6C88.7 198.2 80 186.5 80 173.5c0-16.3 13.2-29.5 29.5-29.5h66.3c12.2 0 24.2 3.7 34.2 10.5 6.1 4.1 14.3 3.1 19.5-2l34.8-34c7.1-6.9 6.1-18.4-1.8-24.5C238 74.8 207.4 64.1 176 64V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48h-2.5C45.8 64-5.4 118.7.5 183.6c4.2 46.1 39.4 83.6 83.8 96.6l102.5 30c12.5 3.7 21.2 15.3 21.2 28.3 0 16.3-13.2 29.5-29.5 29.5h-66.3C100 368 88 364.3 78 357.5c-6.1-4.1-14.3-3.1-19.5 2l-34.8 34c-7.1 6.9-6.1 18.4 1.8 24.5 24.5 19.2 55.1 29.9 86.5 30v48c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-48.2c46.6-.9 90.3-28.6 105.7-72.7 21.5-61.6-14.6-124.8-72.5-141.7z" className="">
                        </path>
                      </svg>
                    </div>
                    <div className="ml-5 w-0 flex-1">
                      <dl>
                        <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                          Total ARR Won
                        </dt>
                        <dd className="flex items-baseline">
                          <div className="text-2xl leading-8 font-semibold text-gray-900">
                            ${arrAmount}
                          </div>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white overflow-hidden shadow rounded-lg">
                <div className="px-4 py-5 sm:p-6">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 bg-indigo-500 rounded-md p-3">
                      <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="lightbulb-on" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" className="h-6 w-6 text-white"><path fill="currentColor" d="M320,64A112.14,112.14,0,0,0,208,176a16,16,0,0,0,32,0,80.09,80.09,0,0,1,80-80,16,16,0,0,0,0-32Zm0-64C217.06,0,143.88,83.55,144,176.23a175,175,0,0,0,43.56,115.55C213.22,321,237.84,368.69,240,384l.06,75.19a15.88,15.88,0,0,0,2.69,8.83l24.5,36.84A16,16,0,0,0,280.56,512h78.85a16,16,0,0,0,13.34-7.14L397.25,468a16.17,16.17,0,0,0,2.69-8.83L400,384c2.25-15.72,27-63.19,52.44-92.22A175.9,175.9,0,0,0,320,0Zm47.94,454.31L350.84,480H289.12l-17.06-25.69,0-6.31h95.91ZM368,416H272l-.06-32H368Zm60.41-145.31c-14,15.95-36.32,48.09-50.57,81.29H262.22c-14.28-33.21-36.6-65.34-50.6-81.29A143.47,143.47,0,0,1,176.06,176C175.88,99,236.44,32,320,32c79.41,0,144,64.59,144,144A143.69,143.69,0,0,1,428.38,270.69ZM96,176a16,16,0,0,0-16-16H16a16,16,0,0,0,0,32H80A16,16,0,0,0,96,176ZM528,64a16.17,16.17,0,0,0,7.16-1.69l64-32A16,16,0,0,0,584.84,1.69l-64,32A16,16,0,0,0,528,64Zm96,96H560a16,16,0,0,0,0,32h64a16,16,0,0,0,0-32ZM119.16,33.69l-64-32A16,16,0,0,0,40.84,30.31l64,32A16.17,16.17,0,0,0,112,64a16,16,0,0,0,7.16-30.31Zm480,288-64-32a16,16,0,0,0-14.32,28.63l64,32a16,16,0,0,0,14.32-28.63ZM112,288a16.17,16.17,0,0,0-7.16,1.69l-64,32a16,16,0,0,0,14.32,28.63l64-32A16,16,0,0,0,112,288Z" className=""></path></svg>
                    </div>
                    <div className="ml-5 w-0 flex-1">
                      <dl>
                        <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                          Total Proof of Concepts Developed
                        </dt>
                        <dd className="flex items-baseline">
                          <div className="text-2xl leading-8 font-semibold text-gray-900">
                            {data.overview.pocs_developed}
                          </div>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white overflow-hidden shadow rounded-lg">
                <div className="px-4 py-5 sm:p-6">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 bg-indigo-500 rounded-md p-3">
                      <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="file-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" className="h-6 w-6 text-white"><path fill="currentColor" d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z" className=""></path></svg>
                    </div>
                    <div className="ml-5 w-0 flex-1">
                      <dl>
                        <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                          Total Whitepaper Written
                        </dt>
                        <dd className="flex items-baseline">
                          <div className="text-2xl leading-8 font-semibold text-gray-900">
                            {data.overview.whitepaper}
                          </div>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white overflow-hidden shadow rounded-lg">
                <div className="px-4 py-5 sm:p-6">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 bg-indigo-500 rounded-md p-3">
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="rocket-launch" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="h-6 w-6 text-white"><path fill="currentColor" d="M505.16405,19.29688c-1.176-5.4629-6.98736-11.26563-12.45106-12.4336C460.61647,0,435.46433,0,410.41962,0,307.2013,0,245.30155,55.20312,199.09162,128H94.88878c-16.29733,0-35.599,11.92383-42.88913,26.49805L2.57831,253.29688A28.39645,28.39645,0,0,0,.06231,264a24.008,24.008,0,0,0,24.00353,24H128.01866a96.00682,96.00682,0,0,1,96.01414,96V488a24.008,24.008,0,0,0,24.00353,24,28.54751,28.54751,0,0,0,10.7047-2.51562l98.747-49.40626c14.56074-7.28515,26.4746-26.56445,26.4746-42.84374V312.79688c72.58882-46.3125,128.01886-108.40626,128.01886-211.09376C512.07522,76.55273,512.07522,51.40234,505.16405,19.29688ZM384.05637,168a40,40,0,1,1,40.00589-40A40.02,40.02,0,0,1,384.05637,168ZM35.68474,352.06641C9.82742,377.91992-2.94985,442.59375.57606,511.41016c69.11565,3.55859,133.61147-9.35157,159.36527-35.10547,40.28913-40.2793,42.8774-93.98633,6.31147-130.54883C129.68687,309.19727,75.97,311.78516,35.68474,352.06641Zm81.63312,84.03125c-8.58525,8.584-30.08256,12.88672-53.11915,11.69922-1.174-22.93555,3.08444-44.49219,11.70289-53.10938,13.42776-13.42578,31.33079-14.28906,43.51813-2.10352C131.60707,404.77148,130.74562,422.67188,117.31786,436.09766Z"></path></svg>
                    </div>
                    <div className="ml-5 w-0 flex-1">
                      <dl>
                        <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                          Total Initiatives Launched
                        </dt>
                        <dd className="flex items-baseline">
                          <div className="text-2xl leading-8 font-semibold text-gray-900">
                            {data.overview.initiatives}
                          </div>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white overflow-hidden shadow rounded-lg">
                <div className="px-4 py-5 sm:p-6">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 bg-indigo-500 rounded-md p-3">
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bug" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="h-6 w-6 text-white"><path fill="currentColor" d="M511.988 288.9c-.478 17.43-15.217 31.1-32.653 31.1H424v16c0 21.864-4.882 42.584-13.6 61.145l60.228 60.228c12.496 12.497 12.496 32.758 0 45.255-12.498 12.497-32.759 12.496-45.256 0l-54.736-54.736C345.886 467.965 314.351 480 280 480V236c0-6.627-5.373-12-12-12h-24c-6.627 0-12 5.373-12 12v244c-34.351 0-65.886-12.035-90.636-32.108l-54.736 54.736c-12.498 12.497-32.759 12.496-45.256 0-12.496-12.497-12.496-32.758 0-45.255l60.228-60.228C92.882 378.584 88 357.864 88 336v-16H32.666C15.23 320 .491 306.33.013 288.9-.484 270.816 14.028 256 32 256h56v-58.745l-46.628-46.628c-12.496-12.497-12.496-32.758 0-45.255 12.498-12.497 32.758-12.497 45.256 0L141.255 160h229.489l54.627-54.627c12.498-12.497 32.758-12.497 45.256 0 12.496 12.497 12.496 32.758 0 45.255L424 197.255V256h56c17.972 0 32.484 14.816 31.988 32.9zM257 0c-61.856 0-112 50.144-112 112h224C369 50.144 318.856 0 257 0z"></path></svg>
                    </div>
                    <div className="ml-5 w-0 flex-1">
                      <dl>
                        <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                          Total Product Bug Reports Filed
                        </dt>
                        <dd className="flex items-baseline">
                          <div className="text-2xl leading-8 font-semibold text-gray-900">
                            {data.overview.bug_reports}
                          </div>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white overflow-hidden shadow rounded-lg">
                <div className="px-4 py-5 sm:p-6">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 bg-indigo-500 rounded-md p-3">
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chalkboard-teacher" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" className="h-6 w-6 text-white"><path fill="currentColor" d="M208 352c-2.39 0-4.78.35-7.06 1.09C187.98 357.3 174.35 360 160 360c-14.35 0-27.98-2.7-40.95-6.91-2.28-.74-4.66-1.09-7.05-1.09C49.94 352-.33 402.48 0 464.62.14 490.88 21.73 512 48 512h224c26.27 0 47.86-21.12 48-47.38.33-62.14-49.94-112.62-112-112.62zm-48-32c53.02 0 96-42.98 96-96s-42.98-96-96-96-96 42.98-96 96 42.98 96 96 96zM592 0H208c-26.47 0-48 22.25-48 49.59V96c23.42 0 45.1 6.78 64 17.8V64h352v288h-64v-64H384v64h-76.24c19.1 16.69 33.12 38.73 39.69 64H592c26.47 0 48-22.25 48-49.59V49.59C640 22.25 618.47 0 592 0z"></path></svg>
                    </div>
                    <div className="ml-5 w-0 flex-1">
                      <dl>
                        <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                          Total New Hire Bootcamp Conducted
                        </dt>
                        <dd className="flex items-baseline">
                          <div className="text-2xl leading-8 font-semibold text-gray-900">
                            {data.overview.bootcamp}
                          </div>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border-b pb-12">
            <div className="max-w-screen-xl mx-auto pt-12 lg:pt-16">
              <h2 className="text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10 text-center">
                  Key Wins
                </h2>
              <div className="grid grid-cols-2 gap-2 md:grid-cols-3 pt-5">
                <div className="col-span-1 flex justify-center bg-gray-100">
                  <img className="max-h-5 object-scale-down h-12 w-full mt-10" src={coupang} alt="Coupang" />
                </div>
                <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-100">
                  <img className="max-h-5 object-scale-down h-12 w-full" src={grab} alt="Grab" />
                </div>
                <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-100">
                  <img className="max-h-5 object-scale-down h-12 w-full" src={amaysim} alt="Amaysim" />
                </div>
                <div className="col-span-1 flex justify-center bg-gray-100">
                  <img className="max-h-5 object-scale-down h-12 w-full mt-8" src={klook} alt="Klook" />
                </div>
                <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-100">
                  <img className="max-h-5 object-scale-down h-12 w-full" src={circles} alt="Circles.Life" />
                </div>
                <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-100">
                  <img className="max-h-5 object-scale-down h-12 w-full" src={swire} alt="Swire Shipping" />
                </div>
              </div>
            </div>
          </div>
          <div className="py-16 bg-gray-50 overflow-hidden lg:py-24 pb-12 border-b">
            <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
              <svg className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4 top-0 right-0" width="202" height="392" fill="none" viewBox="0 0 404 784">
                <defs>
                  <pattern id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width="404" height="784" fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
              </svg>
              <div className="relative text-center">
                <p className="text-base leading-6 text-indigo-600 font-semibold tracking-wide uppercase">Global Initiative</p>
                <h3 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                  Bootstrapper
                </h3>
                <p className="mt-4 max-w-3xl mx-auto text-center text-xl leading-7 text-gray-500">
                  Global initiative that aims to increase the efficiency and productivity of Solution Consultants
                </p>
              </div>
              <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
                <div className="relative">
                  <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
                    One-Click Deployment
                  </h4>
                  <p className="mt-3 text-lg leading-7 text-gray-500">
                    Bootstrapper is a templatization engine that enables you to provision resources in Zendesk based on a defined JSON template. With Bootstrapper's Slack Bot, it allows users to interact with Bootstrapper through a simple and easy to use interface. Users will be able to browse through and leverage on all the available predefined Bootstrapper templates that others have created.
                  </p>
                  <ul className="mt-10">
                    <li>
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="clock" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="h-6 w-6 text-white"><path fill="currentColor" d="M256,8C119,8,8,119,8,256S119,504,256,504,504,393,504,256,393,8,256,8Zm92.49,313h0l-20,25a16,16,0,0,1-22.49,2.5h0l-67-49.72a40,40,0,0,1-15-31.23V112a16,16,0,0,1,16-16h32a16,16,0,0,1,16,16V256l58,42.5A16,16,0,0,1,348.49,321Z"></path></svg>
                          </div>
                        </div>
                        <div className="ml-4">
                          <h5 className="text-lg leading-6 font-medium text-gray-900">Increased Productivity</h5>
                          <p className="mt-2 text-base leading-6 text-gray-500">
                            Increase productivity and efficiency for everyone in the presales organization by cutting down the time and effort required by you to build a customized demo instance
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="mt-10">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                            <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"/>
                            </svg>
                          </div>
                        </div>
                        <div className="ml-4">
                          <h5 className="text-lg leading-6 font-medium text-gray-900">Promote Reusability of Assets</h5>
                          <p className="mt-2 text-base leading-6 text-gray-500">
                            By having a simple way to consume reusable assets, Bootstrapper aims to promote reusability of these assets across all different regions in a simple and scalable way.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="mt-10">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="store" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 616 512" className="h-6 w-6 text-white"><path fill="currentColor" d="M602 118.6L537.1 15C531.3 5.7 521 0 510 0H106C95 0 84.7 5.7 78.9 15L14 118.6c-33.5 53.5-3.8 127.9 58.8 136.4 4.5.6 9.1.9 13.7.9 29.6 0 55.8-13 73.8-33.1 18 20.1 44.3 33.1 73.8 33.1 29.6 0 55.8-13 73.8-33.1 18 20.1 44.3 33.1 73.8 33.1 29.6 0 55.8-13 73.8-33.1 18.1 20.1 44.3 33.1 73.8 33.1 4.7 0 9.2-.3 13.7-.9 62.8-8.4 92.6-82.8 59-136.4zM529.5 288c-10 0-19.9-1.5-29.5-3.8V384H116v-99.8c-9.6 2.2-19.5 3.8-29.5 3.8-6 0-12.1-.4-18-1.2-5.6-.8-11.1-2.1-16.4-3.6V480c0 17.7 14.3 32 32 32h448c17.7 0 32-14.3 32-32V283.2c-5.4 1.6-10.8 2.9-16.4 3.6-6.1.8-12.1 1.2-18.2 1.2z"></path></svg>
                          </div>
                        </div>
                        <div className="ml-4">
                          <h5 className="text-lg leading-6 font-medium text-gray-900">Centralized Assets Marketplace</h5>
                          <p className="mt-2 text-base leading-6 text-gray-500">
                            Provide a centralized marketplace to look for all the demo-ready assets that have been packaged and ready to deploy within a single click of a button
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="mt-10 -mx-4 relative lg:mt-0">
                  <svg className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden inset-y-0 left-0" width="392" height="202" fill="none" viewBox="0 0 784 404">
                    <defs>
                      <pattern id="ca9667ae-9f92-4be7-abcb-9e3d727f2941" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                        <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                      </pattern>
                    </defs>
                    <rect width="784" height="404" fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
                  </svg>
                  <img className="relative mx-auto" width="490" src={bootstrapper} alt="Bootstrapper" />
                </div>
              </div>
              <svg className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12 left-0" width="202" height="392" fill="none" viewBox="0 0 404 784">
                <defs>
                  <pattern id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width="404" height="784" fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
              </svg>
              <div className="relative mt-12 sm:mt-16 lg:mt-24">
                <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
                  <div className="lg:col-start-2">
                    <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
                      Time is money
                    </h4>
                    <p className="mt-3 text-lg leading-7 text-gray-500">
                      Since launching in March 2020, Bootstrapper has helped Zendesk save a significant amount of time in just 1 month.
                    </p>

                    <ul className="mt-10">
                      <li>
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="digging" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" className="h-6 w-6 text-white"><path fill="currentColor" d="M272 96a48 48 0 1 0-48-48 48 48 0 0 0 48 48zm-62.24 261.36q-38.7-25.78-79.55-48.09l-71.56-39-57.42 201a32 32 0 1 0 61.56 17.59l36.6-128.16L160 401.11V480a32 32 0 0 0 64 0v-96a32 32 0 0 0-14.24-26.64zM311.07 416a32 32 0 0 0-30.36 21.88L256 512h320L474.07 305.68c-11.29-22.59-43.07-23.81-56.07-2.15l-31.86 54.29-65.3-35.62L296.85 201a129.78 129.78 0 0 0-69.72-91.2c-1-.5-2.11-.66-3.11-1.13a31 31 0 0 0-7.22-2.67c-15.34-6.1-31.56-10-48.07-10H96a32 32 0 0 0-24.07 10.92l-56 64a25.89 25.89 0 0 0-2.3 3.16c-8.83 14.1-3 32.86 11.62 40.85l336.6 184.3L352 416zM105.9 205l-23.49-12.85L110.54 160h34.33zm93.74 51.13l34.73-41.23 13.5 67.54z"></path></svg>
                            </div>
                          </div>
                          <div className="ml-4 mt-3">
                            <h5 className="text-lg leading-6 font-medium text-gray-900">More than {data.bootstrapper.total_jobs_performed} Bootstrapper Jobs Performed</h5>
                          </div>
                        </div>
                      </li>
                      <li className="mt-10">
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="wrench" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="h-6 w-6 text-white"><path fill="currentColor" d="M507.73 109.1c-2.24-9.03-13.54-12.09-20.12-5.51l-74.36 74.36-67.88-11.31-11.31-67.88 74.36-74.36c6.62-6.62 3.43-17.9-5.66-20.16-47.38-11.74-99.55.91-136.58 37.93-39.64 39.64-50.55 97.1-34.05 147.2L18.74 402.76c-24.99 24.99-24.99 65.51 0 90.5 24.99 24.99 65.51 24.99 90.5 0l213.21-213.21c50.12 16.71 107.47 5.68 147.37-34.22 37.07-37.07 49.7-89.32 37.91-136.73zM64 472c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"></path></svg>
                            </div>
                          </div>
                          <div className="ml-4 mt-3">
                            <h5 className="text-lg leading-6 font-medium text-gray-900">More than {data.bootstrapper.total_api_calls} Zendesk API Calls Made</h5>
                          </div>
                        </div>
                      </li>
                      <li className="mt-10">
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="hourglass" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" className="h-6 w-6 text-white "><path fill="currentColor" d="M360 64c13.255 0 24-10.745 24-24V24c0-13.255-10.745-24-24-24H24C10.745 0 0 10.745 0 24v16c0 13.255 10.745 24 24 24 0 90.965 51.016 167.734 120.842 192C75.016 280.266 24 357.035 24 448c-13.255 0-24 10.745-24 24v16c0 13.255 10.745 24 24 24h336c13.255 0 24-10.745 24-24v-16c0-13.255-10.745-24-24-24 0-90.965-51.016-167.734-120.842-192C308.984 231.734 360 154.965 360 64z"></path></svg>
                            </div>
                          </div>
                          <div className="ml-4 mt-3">
                            <h5 className="text-lg leading-6 font-medium text-gray-900">More than {data.bootstrapper.time_saved_in_hours} hours saved by Bootstrapper</h5>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
                    <svg className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden" width="784" height="404" fill="none" viewBox="0 0 784 404">
                      <defs>
                        <pattern id="e80155a9-dfde-425a-b5ea-1f6fadd20131" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                          <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                        </pattern>
                      </defs>
                      <rect width="784" height="404" fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)" />
                    </svg>
                    <img className="relative mx-auto" width="490" src={bootstrapperJob} alt="Bootstrapper Job" />
                  </div>
                </div>
                <div className="pt-12 bg-gray-50 overflow-hidden">
                  <div className="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="relative">
                      <blockquote className="mt-8">
                        <div className="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-900">
                          <p>
                            &ldquo;....I pitched the idea of Accenture reps being able to run discovery / CX assessment sessions with a client then feed the output into Bootstrapper to build a prototype customer service desk, within seconds, based on actual requirements for the client to use...[Bootstrapper] gave a clear and demonstrable competitive advantage over SFDC and certainly sparked conversations after the event.&rdquo;
                          </p>
                        </div>
                        <footer className="mt-8">
                          <div className="md:flex md:items-center md:justify-center">
                            <div className="md:flex-shrink-0">
                              <img className="mx-auto h-10 w-10 rounded-full" src="https://ca.slack-edge.com/T024F4EL1-UE50JR9MJ-50f0f68149e5-512" alt="" />
                            </div>
                            <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                              <div className="text-base leading-6 font-medium text-gray-900">Peter Tailby</div>

                              <svg className="hidden md:block mx-1 h-5 w-5 text-indigo-600" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M11 0h3L9 20H6l5-20z" />
                              </svg>

                              <div className="text-base leading-6 font-medium text-gray-500">Senior SC, Channel</div>
                            </div>
                          </div>
                        </footer>
                      </blockquote>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="py-12 bg-white">
            <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 border-b pb-12">
              <div className="lg:text-center">
                <p className="text-base leading-6 text-indigo-600 font-semibold tracking-wide uppercase">Leadership</p>
                <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                  Managing Solution Developers
                </h3>
                <p className="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
                  Leading a team of 3 Solution Developers based out of Manila
                </p>
              </div>

              <div className="mt-10">
                <ul className="md:grid md:grid-cols-2 md:col-gap-8 md:row-gap-10">
                  <li>
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                          <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"/>
                          </svg>
                        </div>
                      </div>
                      <div className="ml-4">
                        <h5 className="text-lg leading-6 font-medium text-gray-900">Introduced Best Practices</h5>
                        <p className="mt-2 text-base leading-6 text-gray-500">
                          Introduced numerous best practices (e.g. version Control all projects) to ensure proper standards on developing customized demos
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="mt-10 md:mt-0">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                          <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"/>
                          </svg>
                        </div>
                      </div>
                      <div className="ml-4">
                        <h5 className="text-lg leading-6 font-medium text-gray-900">Reduced Turn Around Time for SCs</h5>
                        <p className="mt-2 text-base leading-6 text-gray-500">
                          Launched an initiative to allow SCs to configure a Customer 360 Demo without the need of any coding.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="mt-10 md:mt-0">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                          <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z"/>
                          </svg>
                        </div>
                      </div>
                      <div className="ml-4">
                        <h5 className="text-lg leading-6 font-medium text-gray-900">Reusable Demos</h5>
                        <p className="mt-2 text-base leading-6 text-gray-500">
                          Revamped the way customized demos are created. Reusability has to be the main component whenever a demo is created.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="mt-10 md:mt-0">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                          <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"/>
                          </svg>
                        </div>
                      </div>
                      <div className="ml-4">
                        <h5 className="text-lg leading-6 font-medium text-gray-900">Mentorship</h5>
                        <p className="mt-2 text-base leading-6 text-gray-500">
                          Provided guidance and coaching to the team on software development and architecture best practices (both technical and non-technical).
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="bg-white">
            <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 border-b pb-12">
              <div className="lg:text-center">
                <p className="text-base leading-6 text-indigo-600 font-semibold tracking-wide uppercase">Stewardship</p>
                <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                  Whitepapers
                </h3>
                <p className="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
                  Educating internal Zendesk employees
                </p>
              </div>
              <div className="mt-12 grid gap-16 border-gray-100 pt-12 lg:grid-cols-3 lg:col-gap-5 lg:row-gap-12">
                    <div>
                      <div>
                        <div className="inline-block">
                          <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-orange-400 text-white">
                            Chat
                          </span>
                        </div>
                      </div>
                      <a href="https://docs.google.com/document/d/1xBswzWhDr1m8OSVuaUGcCRBknJ7UdYvR-gh0cuf0PDQ/edit?usp=sharing" className="block hover:underline" target="_blank" rel="noopener noreferrer">
                        <h3 className="mt-4 text-xl leading-7 font-semibold text-gray-900">
                          Zendesk Chat Client-side PII Redaction
                        </h3>
                        <p className="mt-3 text-base leading-6 text-gray-500">
                           Explore specifically how we can perform Client-side PII Redaction in Zendesk Chat
                        </p>
                      </a>
                      <div className="mt-6 flex items-center">
                        <div className="flex-shrink-0">
                          <span>
                            <img className="h-10 w-10 rounded-full" src="https://en.gravatar.com/userimage/925970/6550edd7553d518667f66a1da071ecb3.jpg?size=200" alt="" />
                          </span>
                        </div>
                        <div className="ml-3">
                          <p className="text-sm leading-5 font-medium text-gray-900">
                            <span>
                              Leroy Chan
                            </span>
                          </p>
                          <div className="flex text-sm leading-5 text-gray-500">
                            <time dateTime="2020-03-16">
                              3 Apr 2020
                            </time>
                            <span className="mx-1">
                              &middot;
                            </span>
                            <span>
                              6 min read
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div className="inline-block">
                          <div className="inline-block">
                            <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-purple-400 text-white">
                              Sunshine Conversations
                            </span>
                          </div>
                        <div className="inline-block ml-2">
                          <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-orange-400 text-white">
                            Chat
                          </span>
                        </div>
                        </div>
                      </div>
                      <a href="https://docs.google.com/document/d/1ZS4q2MX9dkylCZkA7NJuWzvluyIeo23Bt79WsAbv6Lw/edit?usp=sharing" className="block hover:underline" target="_blank" rel="noopener noreferrer">
                        <h3 className="mt-4 text-xl leading-7 font-semibold text-gray-900">
                          Chat to WhatsApp Handover
                        </h3>
                        <p className="mt-3 text-base leading-6 text-gray-500">
                          Transfering from Zendesk Chat to WhatsApp during  Chat session
                        </p>
                      </a>
                      <div className="mt-6 flex items-center">
                        <div className="flex-shrink-0">
                          <span>
                            <img className="h-10 w-10 rounded-full" src="https://en.gravatar.com/userimage/925970/6550edd7553d518667f66a1da071ecb3.jpg?size=200" alt="" />
                          </span>
                        </div>
                        <div className="ml-3">
                          <p className="text-sm leading-5 font-medium text-gray-900">
                            <span>
                              Leroy Chan
                            </span>
                          </p>
                          <div className="flex text-sm leading-5 text-gray-500">
                            <time dateTime="2020-03-16">
                              8 Nov 2019
                            </time>
                            <span className="mx-1">
                              &middot;
                            </span>
                            <span>
                              4 min read
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div className="inline-block">
                          <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-blue-400 text-white">
                            Support
                          </span>
                        </div>
                        <div className="inline-block ml-2">
                          <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-orange-400 text-white">
                            Chat
                          </span>
                        </div>
                      </div>
                      <a href="https://docs.google.com/document/d/1N8DP5k7MGT84AUFtG1ob2E8DxzCgpxCHn4QIR7XYXd0/edit?usp=sharing" className="block hover:underline" target="_blank" rel="noopener noreferrer">
                        <h3 className="mt-4 text-xl leading-7 font-semibold text-gray-900">
                          Data Locality of Personally Identifiable Information
                        </h3>
                        <p className="mt-3 text-base leading-6 text-gray-500">
                          Solution focuses on storing the minimal customer information within the Zendesk instance while at the same time allowing Zendesk customers to store customer information outside of the Zendesk instance
                        </p>
                      </a>
                      <div className="mt-6 flex items-center">
                        <div className="flex-shrink-0">
                          <span>
                            <img className="h-10 w-10 rounded-full" src="https://en.gravatar.com/userimage/925970/6550edd7553d518667f66a1da071ecb3.jpg?size=200" alt="" />
                          </span>
                        </div>
                         <div className="ml-3">
                          <p className="text-sm leading-5 font-medium text-gray-900">
                            <span>
                              Leroy Chan
                            </span>
                          </p>
                          <div className="flex text-sm leading-5 text-gray-500">
                            <time dateTime="2020-03-16">
                              13 Aug 2019
                            </time>
                            <span className="mx-1">
                              &middot;
                            </span>
                            <span>
                              8 min read
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
            </div>
          </div>
          <div className="relative bg-gray-50 pt-12 pb-20 px-4 sm:px-6 lg:pt-12 lg:pb-28 lg:px-8 border-b">
            <div className="absolute inset-0">
              <div className="bg-white h-1/3 sm:h-2/3"></div>
            </div>
            <div className="relative max-w-7xl mx-auto">
              <div className="text-center">
                <p className="text-base leading-6 text-indigo-600 font-semibold tracking-wide uppercase">Client Value Creation</p>
                <h2 className="text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
                  Proof of Concepts
                </h2>
                <p className="mt-3 max-w-2xl mx-auto text-xl leading-7 text-gray-500 sm:mt-4">
                  Developing demonstrable competitive advantage over competitors
                </p>
              </div>
              <div className="mt-12 grid gap-5 max-w-lg mx-auto lg:grid-cols-3 lg:max-w-none">
                <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                  <div className="flex-shrink-0">
                    <img className="h-48 w-full object-cover" src={pocTriagingBot} alt="" />
                  </div>
                  <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                    <div className="flex-1">
                      <p className="text-sm leading-5 font-medium text-indigo-600">
                        <span>
                          Support | AWS Lex | AWS Comprehend
                        </span>
                      </p>
                      <a href="https://drive.google.com/file/d/1rLzKbWCLYiY4XC8K0kA0nsTdCgchnZGu/view?usp=sharing" className="block hover:underline" target="_blank" rel="noopener noreferrer">
                        <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900">
                          Automated Triaging Bot on Support
                        </h3>
                        <p className="mt-3 text-base leading-6 text-gray-500">
                          Perform automated triaging using Machine Learning and AI
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                  <div className="flex-shrink-0">
                    <img className="h-48 w-full object-cover" src={pocChatPayment} alt="" />
                  </div>
                  <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                    <div className="flex-1">
                      <p className="text-sm leading-5 font-medium text-indigo-600">
                        <span>
                          Chat | Stripe
                        </span>
                      </p>
                      <a href="https://drive.google.com/file/d/1UzBMQ2ftQwECzF0YdoLD1QbZ7NSEjaAW/view?usp=sharing" className="block hover:underline" target="_blank" rel="noopener noreferrer">
                        <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900">
                          Secured Payment within Chat
                        </h3>
                        <p className="mt-3 text-base leading-6 text-gray-500">
                          Perform the entire transaction of purchasing an order, including payment, securely within Chat
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                  <div className="flex-shrink-0">
                    <img className="h-48 w-full object-cover" src={pocChatRedaction} alt="" />
                  </div>
                  <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                    <div className="flex-1">
                      <p className="text-sm leading-5 font-medium text-indigo-600">
                        <span>
                          Support | Chat
                        </span>
                      </p>
                      <a href="https://drive.google.com/file/d/18I3okueC2VPFR0hFLkvKlwFPVPiAimKC/view?usp=sharing" className="block hover:underline" target="_blank" rel="noopener noreferrer">
                        <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900">
                          Chat PII Redaction
                        </h3>
                        <p className="mt-3 text-base leading-6 text-gray-500">
                          Allowing agents to view pre-redacted chat messages even without storing PII in Zendesk servers
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                  <div className="flex-shrink-0">
                    <img className="h-48 w-full object-cover" src={pocCSATWhatsApp} alt="" />
                  </div>
                  <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                    <div className="flex-1">
                      <p className="text-sm leading-5 font-medium text-indigo-600">
                        <span>
                          Support | Sunshine Conversations
                        </span>
                      </p>
                      <a href="https://drive.google.com/file/d/16zJjTuADbbYe2nIqBUQQpDPyqe3BVEaY/view?usp=sharing" className="block hover:underline" target="_blank" rel="noopener noreferrer">
                        <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900">
                          Zendesk CSAT Survey via WhatsApp
                        </h3>
                        <p className="mt-3 text-base leading-6 text-gray-500">
                          Sending of Zendesk's CSAT Survey via WhatsApp to end-users
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                  <div className="flex-shrink-0">
                    <img className="h-48 w-full object-cover" src={pocTranslation} alt="" />
                  </div>
                  <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                    <div className="flex-1">
                      <p className="text-sm leading-5 font-medium text-indigo-600">
                        <span>
                          Support | AWS Comprehend | Yandex
                        </span>
                      </p>
                      <a href="https://drive.google.com/file/d/11jJfxhBHPLQj_kCYgPIhHMqU9FgqAx4p/view?usp=sharing" className="block hover:underline" target="_blank" rel="noopener noreferrer">
                        <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900">
                          Sentiment Analysis with Translation
                        </h3>
                        <p className="mt-3 text-base leading-6 text-gray-500">
                          Detection of non-English language before applying translation and sentiment analysis
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                  <div className="flex-shrink-0">
                    <img className="h-48 w-full object-cover" src={pocMultilingual} alt="" />
                  </div>
                  <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                    <div className="flex-1">
                      <p className="text-sm leading-5 font-medium text-indigo-600">
                        <span>
                          Sunshine Conversations | AWS Lambda
                        </span>
                      </p>
                      <a href="https://drive.google.com/file/d/1IgoZ92VPIAN8d_d1QixVqNXadLYGAHOc/view?usp=sharing" className="block hover:underline" target="_blank" rel="noopener noreferrer">
                        <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900">
                          Multilingual Social Chatbot
                        </h3>
                        <p className="mt-3 text-base leading-6 text-gray-500">
                          Social messaging Chatbot that supports multiple languages
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                  <div className="flex-shrink-0">
                    <img className="h-48 w-full object-cover" src={pocOrderInventory} alt="" />
                  </div>
                  <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                    <div className="flex-1">
                      <p className="text-sm leading-5 font-medium text-indigo-600">
                        <span>
                          Sunsine Conversations | AWS Lambda
                        </span>
                      </p>
                      <a href="https://drive.google.com/file/d/13Em3a_3lb8PQodMdAzXxNZqvQ24RCx_V/view?usp=sharing" className="block hover:underline" target="_blank" rel="noopener noreferrer">
                        <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900">
                          Ordering Inventory Social Chatbot
                        </h3>
                        <p className="mt-3 text-base leading-6 text-gray-500">
                          Allow staffs to order inventory through social messaging channels
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                  <div className="flex-shrink-0">
                    <img className="h-48 w-full object-cover" src={pocMultipleTickets} alt="" />
                  </div>
                  <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                    <div className="flex-1">
                      <p className="text-sm leading-5 font-medium text-indigo-600">
                        <span>
                          Support | AWS Lambda
                        </span>
                      </p>
                      <a href="https://drive.google.com/file/d/14YfKKowqIgauF27iDS9YXdbFJI59znUe/view?usp=sharing" className="block hover:underline" target="_blank" rel="noopener noreferrer">
                        <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900">
                          Creating Multiple Tickets with One Email
                        </h3>
                        <p className="mt-3 text-base leading-6 text-gray-500">
                          Create multiple tickets within the same Zendesk instance based on the Support email addresses that are present in “To:” email field
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                  <div className="flex-shrink-0">
                    <img className="h-48 w-full object-cover" src={pocClickToChat} alt="" />
                  </div>
                  <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                    <div className="flex-1">
                      <p className="text-sm leading-5 font-medium text-indigo-600">
                        <span>
                          Support | Chat
                        </span>
                      </p>
                      <a href="https://drive.google.com/file/d/17iaBJQIaL4h_SuZ4XL2B73cxu84ZeMWd/view?usp=sharing" className="block hover:underline" target="_blank" rel="noopener noreferrer">
                        <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900">
                          Click to Chat
                        </h3>
                        <p className="mt-3 text-base leading-6 text-gray-500">
                          Allow agents to send a link for customers to start chatting
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                  <div className="flex-shrink-0">
                    <img className="h-48 w-full object-cover" src={pocCrossChannelBot} alt="" />
                  </div>
                  <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                    <div className="flex-1">
                      <p className="text-sm leading-5 font-medium text-indigo-600">
                        <span>
                          Sunshine Conversations | AWS Lambda
                        </span>
                      </p>
                      <a href="https://drive.google.com/file/d/17Gk_ip2ipWltmB_kZ-RuPCpPGY71oaFm/view?usp=sharing" className="block hover:underline" target="_blank" rel="noopener noreferrer">
                        <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900">
                          Cross Channel Social Chatbot
                        </h3>
                        <p className="mt-3 text-base leading-6 text-gray-500">
                          Single Chatbot that works for multiple social messaging channels
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                  <div className="flex-shrink-0">
                    <img className="h-48 w-full object-cover" src={pocRandomizedGreetings} alt="" />
                  </div>
                  <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                    <div className="flex-1">
                      <p className="text-sm leading-5 font-medium text-indigo-600">
                        <span>
                          Support
                        </span>
                      </p>
                      <a href="https://drive.google.com/file/d/1Q4aFZHfEF4G_4UuziXMnEHV2Uvu4gdc0/view?usp=sharing" className="block hover:underline" target="_blank" rel="noopener noreferrer">
                        <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900">
                          Randomized Greeting using Macros
                        </h3>
                        <p className="mt-3 text-base leading-6 text-gray-500">
                          Using Liquid Markup to randomized greeting with a single Macro
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                  <div className="flex-shrink-0">
                    <img className="h-48 w-full object-cover" src={pocAmazonConnect} alt="" />
                  </div>
                  <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                    <div className="flex-1">
                      <p className="text-sm leading-5 font-medium text-indigo-600">
                        <span>
                          Support | AWS Connect
                        </span>
                      </p>
                      <a href="https://drive.google.com/file/d/1GwaVqdBD9Cnc6jOaiVqctiqpBUuIy5Xn/view?usp=sharing" className="block hover:underline" target="_blank" rel="noopener noreferrer">
                        <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900">
                          Amazon Connect with Zendesk
                        </h3>
                        <p className="mt-3 text-base leading-6 text-gray-500">
                          Showcasing IVRs and call pickups using Amazon Connect with Zendesk
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>


          <div className="overflow-hidden mt-12">
            <div className="text-center">
                <p className="text-base leading-6 text-indigo-600 font-semibold tracking-wide uppercase">Teamwork</p>
                <h2 className="text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
                  Going Above and Beyond
                </h2>
                <p className="mt-3 max-w-2xl mx-auto text-xl leading-7 text-gray-500 sm:mt-4">
                  Continously working with cross-functional cross-region teams to make Zendesk a CRM and Platform company
                </p>
              </div>
            <div className="relative max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
              <svg className="absolute top-0 left-full transform -translate-x-1/2 -translate-y-3/4 lg:left-auto lg:right-full lg:translate-x-2/3 lg:translate-y-1/4" width="404" height="784" fill="none" viewBox="0 0 404 784">
                <defs>
                  <pattern id="8b1b5f72-e944-4457-af67-0c6d15a99f38" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width="404" height="784" fill="url(#8b1b5f72-e944-4457-af67-0c6d15a99f38)" />
              </svg>

              <div className="relative lg:grid lg:grid-cols-3 lg:col-gap-8">
                <div className="lg:col-span-1">
                  <h3 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                    Like a true Zendevian
                  </h3>
                </div>
                <div className="mt-10 sm:grid sm:grid-cols-2 sm:col-gap-8 sm:row-gap-10 lg:col-span-2 lg:mt-0">
                  <div>
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="users" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" className="h-6 w-6 text-white"><path fill="currentColor" d="M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6 40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z"></path></svg>
                    </div>
                    <div className="mt-5">
                      <h5 className="text-lg leading-6 font-medium text-gray-900">Collaboration with Product Managers</h5>
                      <p className="mt-2 text-base leading-6 text-gray-500">
                        Work closely with PMs for Sunshine (Shannon Anahata, James Hanley, Erica Wass, Lau Jian Wei, Fred Li) and Chat (Chao Yu Hsuan, Ramin Shokrizadeh) to provide continous feedback on the products
                      </p>
                    </div>
                  </div>
                  <div className="mt-10 sm:mt-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bug" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="h-6 w-6 text-white"><path fill="currentColor" d="M511.988 288.9c-.478 17.43-15.217 31.1-32.653 31.1H424v16c0 21.864-4.882 42.584-13.6 61.145l60.228 60.228c12.496 12.497 12.496 32.758 0 45.255-12.498 12.497-32.759 12.496-45.256 0l-54.736-54.736C345.886 467.965 314.351 480 280 480V236c0-6.627-5.373-12-12-12h-24c-6.627 0-12 5.373-12 12v244c-34.351 0-65.886-12.035-90.636-32.108l-54.736 54.736c-12.498 12.497-32.759 12.496-45.256 0-12.496-12.497-12.496-32.758 0-45.255l60.228-60.228C92.882 378.584 88 357.864 88 336v-16H32.666C15.23 320 .491 306.33.013 288.9-.484 270.816 14.028 256 32 256h56v-58.745l-46.628-46.628c-12.496-12.497-12.496-32.758 0-45.255 12.498-12.497 32.758-12.497 45.256 0L141.255 160h229.489l54.627-54.627c12.498-12.497 32.758-12.497 45.256 0 12.496 12.497 12.496 32.758 0 45.255L424 197.255V256h56c17.972 0 32.484 14.816 31.988 32.9zM257 0c-61.856 0-112 50.144-112 112h224C369 50.144 318.856 0 257 0z"></path></svg>
                    </div>
                    <div className="mt-5">
                      <h5 className="text-lg leading-6 font-medium text-gray-900">Production Bug Reports</h5>
                      <p className="mt-2 text-base leading-6 text-gray-500">
                        During the in-depth exploration of Zendesk Products, numerous bugs have been unravelled. To date, {data.overview.bug_reports} Production bugs have been filed.
                      </p>
                    </div>
                  </div>
                  <div className="mt-10 sm:mt-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="hands-helping" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" className="h-6 w-6 text-white"><path fill="currentColor" d="M488 192H336v56c0 39.7-32.3 72-72 72s-72-32.3-72-72V126.4l-64.9 39C107.8 176.9 96 197.8 96 220.2v47.3l-80 46.2C.7 322.5-4.6 342.1 4.3 357.4l80 138.6c8.8 15.3 28.4 20.5 43.7 11.7L231.4 448H368c35.3 0 64-28.7 64-64h16c17.7 0 32-14.3 32-32v-64h8c13.3 0 24-10.7 24-24v-48c0-13.3-10.7-24-24-24zm147.7-37.4L555.7 16C546.9.7 527.3-4.5 512 4.3L408.6 64H306.4c-12 0-23.7 3.4-33.9 9.7L239 94.6c-9.4 5.8-15 16.1-15 27.1V248c0 22.1 17.9 40 40 40s40-17.9 40-40v-88h184c30.9 0 56 25.1 56 56v28.5l80-46.2c15.3-8.9 20.5-28.4 11.7-43.7z"></path></svg>
                    </div>
                    <div className="mt-5">
                      <h5 className="text-lg leading-6 font-medium text-gray-900">Lending a Helping Hand</h5>
                      <p className="mt-2 text-base leading-6 text-gray-500">
                        Actively involved in answering questions on the following Slack channel: #ask-sunshine, #apac-presales, #wwpresales
                      </p>
                    </div>
                  </div>
                  <div className="mt-10 sm:mt-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chess-knight-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="h-6 w-6 text-white"><path fill="currentColor" d="M311.16 459.58L288 448v-16a16 16 0 0 0-16-16H48a16 16 0 0 0-16 16v16L8.85 459.58A16 16 0 0 0 0 473.89V496a16 16 0 0 0 16 16h288a16 16 0 0 0 16-16v-22.11a16 16 0 0 0-8.84-14.31zM45.55 235.52l28.83 12.86a22.59 22.59 0 0 0 17.67.34l9.09-3.65a22.79 22.79 0 0 0 13.33-14.62l6.53-21.87a17.09 17.09 0 0 1 8.92-11.15l14.2-5.43v37.21a28.58 28.58 0 0 1-16.9 26.09L80.68 279C40.87 299.22 32.42 352.42 64 384h192c7-7.85 16-18.31 16-32V203.16C272 126.62 209.38 64 132.84 64H40.52A8.54 8.54 0 0 0 32 72.56 12.14 12.14 0 0 0 33.27 78l10.1 20.28-6.37 6.35a17.21 17.21 0 0 0-5 12.11v97.9a22.86 22.86 0 0 0 13.55 20.88zM80.07 128a16 16 0 1 1-15.94 16 16 16 0 0 1 15.94-16z"></path></svg>
                    </div>
                    <div className="mt-5">
                      <h5 className="text-lg leading-6 font-medium text-gray-900">Subject Matter Expert</h5>
                      <p className="mt-2 text-base leading-6 text-gray-500">
                        Go-to person in APAC Presales for anything technical across <b>all</b> Zendesk Products
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
    </div>
  );
};

export default Zendesk;